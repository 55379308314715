var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('div',{staticClass:"container"},[_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 12 },"colon":false},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"会议主题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'title',
              { rules: [{ required: true, message: '请输入！' }] },
            ]),expression:"[\n              'title',\n              { rules: [{ required: true, message: '请输入！' }] },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"会议室"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'roomId',
              { rules: [{ required: true, message: '请选择！' }] },
            ]),expression:"[\n              'roomId',\n              { rules: [{ required: true, message: '请选择！' }] },\n            ]"}],on:{"change":_vm.handleRoomChange}},_vm._l((_vm.meetingRoomList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"会议日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'date',
              { rules: [{ required: true, message: '请选择！' }] },
            ]),expression:"[\n              'date',\n              { rules: [{ required: true, message: '请选择！' }] },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":""},on:{"change":_vm.handleDateChange}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"申请服务","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['serviceList']),expression:"['serviceList']"}],attrs:{"options":_vm.serviceList.map((item) => ({
              label: item.name,
              value: item.value,
            }))}}),_c('div',{staticStyle:{"color":"#999"}},[_vm._v("请至少提前一天联系相关人员对接会议需求")])],1)],1),(_vm.timeList.length > 0)?_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('span',{staticClass:"required",attrs:{"slot":"label"},slot:"label"},[_vm._v("会议时间")]),_c('table',{staticStyle:{"margin-top":"4px"}},[_c('tr',_vm._l((_vm.timeList),function(item,index){return _c('a-tooltip',{key:index},[_c('template',{slot:"title"},[(item.disabled)?_c('span',[_vm._v("已禁用")]):(item.active)?_c('span',[_vm._v("已选择")]):_c('span',[_vm._v("请选择")])]),_c('td',{staticClass:"time",class:[
                    item.disabled ? 'disabled' : '',
                    _vm.setActive(index) ? 'active' : '',
                  ],on:{"click":function($event){return _vm.selectTime(item, index)}}})],2)}),1),_c('tr',_vm._l((_vm.timeList.filter(
                  (item) => item.time.indexOf('00') > -1
                )),function(item,index){return _c('td',{key:index,staticClass:"value",attrs:{"colspan":"2"}},[_c('span',[_vm._v(" "+_vm._s(item.time)+" ")])])}),0)])]),_c('a-form-item',{attrs:{"wrapper-col":{ span: 18, offset: 4 }}},[(typeof _vm.selectedStartIndex === 'number' &&
              typeof _vm.selectedEndIndex === 'number'
              )?_c('div',{staticStyle:{"margin-top":"-12px"}},[_vm._v(" "+_vm._s(_vm.timeList[_vm.selectedStartIndex].time)+" - "+_vm._s(_vm.selectedEndIndex === _vm.timeList.length - 1 ? "22:00" : _vm.timeList[_vm.selectedEndIndex + 1].time)+" "),_c('span',{staticStyle:{"padding-left":"8px","cursor":"pointer","color":"#f00"},on:{"click":_vm.removeSelected}},[_vm._v(" 清空选择 ")])]):_vm._e()])],1):_vm._e(),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"会议内容","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['content']),expression:"['content']"}],attrs:{"auto-size":{ minRows: 3 }}})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"参会人员","label-col":{ span: 4 },"wrapper-col":{ span: 18 }}},[_c('div',[_c('EmployeeSelector',{attrs:{"single":false,"value":_vm.attendeeList},on:{"change":(arr) => {
                  _vm.attendeeList = arr;
                }}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v("添加参会人员")])],1),_c('Padding'),_c('a-table',{attrs:{"data-source":_vm.attendeeList,"bordered":"","pagination":false}},[_c('a-table-column',{attrs:{"title":"部门","data-index":"deptUniqueName"}}),_c('a-table-column',{attrs:{"title":"姓名","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"手机号","data-index":"mobile"}})],1)],1)])],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saveLoading}},[_vm._v("保存")]),_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }