<template>
  <div>
    <Pane />

    <div class="container">
      <a-form :form="form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false" @submit="handleSubmit">
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="会议主题">
              <a-input v-decorator="[
                'title',
                { rules: [{ required: true, message: '请输入！' }] },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="会议室">
              <a-select @change="handleRoomChange" v-decorator="[
                'roomId',
                { rules: [{ required: true, message: '请选择！' }] },
              ]">
                <a-select-option v-for="item in meetingRoomList" :key="item.id" :value="item.id">{{ item.name
                }}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="会议日期">
              <a-date-picker placeholder="" @change="handleDateChange" style="width: 100%" v-decorator="[
                'date',
                { rules: [{ required: true, message: '请选择！' }] },
              ]" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="申请服务" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-checkbox-group v-decorator="['serviceList']" :options="serviceList.map((item) => ({
                label: item.name,
                value: item.value,
              }))
                " />

              <div style="color: #999">请至少提前一天联系相关人员对接会议需求</div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24" v-if="timeList.length > 0">
            <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <span slot="label" class="required">会议时间</span>

              <table style="margin-top: 4px">
                <tr>
                  <a-tooltip v-for="(item, index) in timeList" :key="index">
                    <template slot="title">
                      <span v-if="item.disabled">已禁用</span>
                      <span v-else-if="item.active">已选择</span>
                      <span v-else>请选择</span>
                    </template>
                    <td class="time" @click="selectTime(item, index)" :class="[
                      item.disabled ? 'disabled' : '',
                      setActive(index) ? 'active' : '',
                    ]"></td>
                  </a-tooltip>
                </tr>
                <tr>
                  <td class="value" colspan="2" v-for="(item, index) in timeList.filter(
                    (item) => item.time.indexOf('00') > -1
                  )" :key="index">
                    <span>
                      {{ item.time }}
                    </span>
                  </td>
                </tr>
              </table>
            </a-form-item>

            <a-form-item :wrapper-col="{ span: 18, offset: 4 }">
              <div style="margin-top: -12px" v-if="typeof selectedStartIndex === 'number' &&
                typeof selectedEndIndex === 'number'
                ">
                {{ timeList[selectedStartIndex].time }} -
                {{
                  selectedEndIndex === timeList.length - 1
                  ? "22:00"
                  : timeList[selectedEndIndex + 1].time
                }}

                <span style="padding-left: 8px; cursor: pointer; color: #f00" @click="removeSelected">
                  清空选择
                </span>
              </div>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="会议内容" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <a-textarea :auto-size="{ minRows: 3 }" v-decorator="['content']" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="参会人员" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <div>
                <EmployeeSelector :single="false" @change="(arr) => {
                    attendeeList = arr;
                  }
                  " :value="attendeeList">
                  <a-button type="primary">添加参会人员</a-button>
                </EmployeeSelector>

                <Padding />
                <a-table :data-source="attendeeList" bordered :pagination="false">
                  <a-table-column title="部门" data-index="deptUniqueName"></a-table-column>
                  <a-table-column title="姓名" data-index="name"></a-table-column>
                  <a-table-column title="手机号" data-index="mobile"></a-table-column>
                </a-table>
              </div>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="saveLoading">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import {
  fetchList,
  fetchMeetingList,
  fetchMeetingDetail,
  editMeeting,
} from "@/api/meeting";
import { mapGetters } from "vuex";

import { mapState } from "vuex";
import moment from "moment";
import EmployeeSelector from "@/components/employee-selector";
export default {
  name: "editMeeting",

  components: {
    EmployeeSelector,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      saveLoading: false,
      meetingRoomList: [], // 会议室列表
      timeList: [], // 时间节点列表
      selectedStartIndex: null, // 选择的开始时间索引
      selectedEndIndex: null, // 选择的结束时间索引
      attendeeList: [], // 选择的员工列表

      id: null,
      editingObj: {},
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    serviceList() {
      return this.findDataDict("meetBookService");
    },

    ...mapState("auth", ["user"]),

    // 提示语集合
    messageList() {
      return this.findDataDict("message");
    },
  },

  activated() {
    fetchList({
      pageNum: 1,
      pageSize: 100,
    }).then((res) => {
      if (Array.isArray(res.list)) {
        this.meetingRoomList = res.list;
      }
    });

    // 如果用户自己就是管理员，并且在会议列表页面通过了一条预约记录，再回到这个页面，如果用户已经选择了时间和会议室，那么时间节点列表就是旧的数据，需要刷新一下。
    const roomId = this.form.getFieldValue("roomId");
    const date = this.form.getFieldValue("date");
    if (roomId && date) {
      this.getTimeList(roomId, date, this.editingObj);
    }

    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;

      this.getDetail(id);
    }
  },

  methods: {
    getDetail(id) {
      fetchMeetingDetail({ id }).then((res) => {
        if (res) {
          this.editingObj = res;
          let arr = [];
          if (Array.isArray(res.serviceList)) {
            arr = res.serviceList.map((item) => item.code);
          }

          this.form.setFieldsValue({
            roomId: res.roomId,
            date: moment(res.startTime),
            title: res.title,
            serviceList: arr,
            content: res.content,
          });

          this.getTimeList(res.roomId, moment(res.startTime), res);

          if (Array.isArray(res.attendeeList)) {
            this.attendeeList = res.attendeeList.map((item) => {
              return {
                userId: item.userId,
                name: item.userName,
              };
            });
          }
        }
      });
    },

    handleRoomChange(id) {
      this.getTimeList(id, this.form.getFieldValue("date"), this.editingObj);
    },
    handleDateChange(date) {
      this.getTimeList(
        this.form.getFieldValue("roomId"),
        date,
        this.editingObj
      );
    },

    // 获取某个会议室的时间列表，看其中是否有空闲时间
    getTimeList(roomId, date, meetingInfo) {
      console.log("change ", roomId, date);

      if (roomId && date) {
        const now = moment();
        console.log(date.format("YYYY-MM-DD HH:mm:ss"));
        console.log(now.format("YYYY-MM-DD HH:mm:ss"));

        if (!now.isSameOrBefore(date, "day")) {
          this.$message.error("最早选择今日！");
          return;
        }

        fetchMeetingList({
          pageNum: 1,
          pageSize: 1000,
          roomId,
          startTime: date.format("YYYY-MM-DD"),
          endTime: date.format("YYYY-MM-DD"),
        }).then((res) => {
          console.log("res", res);
          if (Array.isArray(res.list)) {
            const filterList = res.list.filter(
              (item) =>
                item.state === "approved" ||
                item.state === "applying" ||
                item.state === "meeting" ||
                item.state === "end"
            );

            const time = [];

            for (let i = 7; i < 22; i++) {
              time.push({
                time: `${i < 10 ? "0" + i : i}:00`,
              });
              time.push({
                time: `${i < 10 ? "0" + i : i}:30`,
              });
            }

            if (meetingInfo) {
              if (meetingInfo.startTime && meetingInfo.endTime) {
                const startArr = meetingInfo.startTime.split(" ");
                const endArr = meetingInfo.endTime.split(" ");
                if (startArr.length > 1 && endArr.length > 1) {
                  const startTimeStr = startArr[1];
                  const endTimeStr = endArr[1];

                  console.log("startTimeArr", startTimeStr, endTimeStr);

                  let startIndex, endIndex;
                  time.forEach((element, index) => {
                    if (element.time === startTimeStr) {
                      startIndex = index;
                    }
                    if (element.time === endTimeStr) {
                      endIndex = index - 1;
                    }
                  });

                  this.selectedStartIndex = startIndex;
                  this.selectedEndIndex = endIndex;
                }
              }
            }

            if (filterList.length === 0) {
              this.timeList = time;
            } else {
              filterList.forEach((item) => {
                const { startTime, endTime } = item;
                if (startTime && endTime) {
                  const startArr = startTime.split(" ");
                  const endArr = endTime.split(" ");
                  if (startArr.length > 1 && endArr.length > 1) {
                    const time1 = startArr[1];
                    const time2 = endArr[1];

                    let startIndex, endIndex;
                    time.forEach((element, index) => {
                      if (element.time === time1) {
                        startIndex = index;
                      }
                      if (element.time === time2) {
                        endIndex = index;
                      }
                    });

                    for (let i = startIndex; i < endIndex; i++) {
                      // 如果编辑状态，用户已经选择的时间节点和重新刷新的时间节点重复，则把用户原先选择的删除掉
                      if (i === this.selectedStartIndex) {
                        this.selectedStartIndex = null;
                      }
                      if (i === this.selectedEndIndex) {
                        this.selectedEndIndex = null;
                      }

                      time[i].disabled = true;
                    }
                  }
                }
              });

              this.timeList = time;
            }
          }
        });
      }
    },

    selectTime(item, index) {
      if (item.disabled) {
        return;
      }

      // 用户选中某个时间节点
      if (typeof this.selectedStartIndex !== "number") {
        if (typeof this.selectedEndIndex === "number") {
          if (index < this.selectedEndIndex) {
            if (this.checkTime(index, this.selectedEndIndex)) {
              this.selectedStartIndex = index;
            } else {
              this.$message.error("请选择正确的时间范围！");
              return;
            }
          } else {
            if (this.checkTime(this.selectedEndIndex, index)) {
              this.selectedStartIndex = this.selectedEndIndex;
              this.selectedEndIndex = index;
            } else {
              this.$message.error("请选择正确的时间范围！");
              return;
            }
          }
        } else {
          this.selectedStartIndex = index;
        }

        this.selectedStartIndex = index;
      } else if (typeof this.selectedEndIndex !== "number") {
        if (index < this.selectedStartIndex) {
          // 校验开始和结束时间范围内有没有禁用的节点（别人申请了），如果有则，不允许点击
          if (this.checkTime(index, this.selectedStartIndex)) {
            this.selectedStartIndex = index;
            this.selectedEndIndex = this.selectedStartIndex;
          } else {
            this.$message.error("请选择正确的时间范围！");
            return;
          }
        } else {
          if (this.checkTime(this.selectedStartIndex, index)) {
            this.selectedEndIndex = index;
          } else {
            this.$message.error("请选择正确的时间范围！");
            return;
          }
        }
      } // 用户取消选中某个时间节点
      else if (index === this.selectedStartIndex) {
        this.selectedStartIndex = null;
      } else if (index === this.selectedEndIndex) {
        this.selectedEndIndex = null;
      } else {
        // 开始和结束时间都已选中，用户重新点选了时间节点，需要判断用户选中的节点所在的位置。
        const median = (this.selectedStartIndex + this.selectedEndIndex) / 2;
        if (index <= median) {
          if (this.checkTime(index, this.selectedEndIndex)) {
            this.selectedStartIndex = index;
          } else {
            this.$message.error("请选择正确的时间范围！");
            return;
          }
        } else {
          if (this.checkTime(this.selectedStartIndex, index)) {
            this.selectedEndIndex = index;
          } else {
            this.$message.error("请选择正确的时间范围！");
            return;
          }
        }
      }
    },

    setActive(index) {
      const startType = typeof this.selectedStartIndex === "number";
      const endType = typeof this.selectedEndIndex === "number";
      if (startType && endType) {
        return (
          index >= this.selectedStartIndex && index <= this.selectedEndIndex
        );
      } else if (startType) {
        return index === this.selectedStartIndex;
      } else if (endType) {
        return index === this.selectedEndIndex;
      } else {
        return false;
      }
    },

    // 检查用户
    checkTime(startIndex, endIndex) {
      let flag = true;
      for (let i = startIndex; i <= endIndex; i++) {
        let item = this.timeList[i];
        if (item.disabled) {
          flag = false;
          break;
        }
      }

      console.log("check result", flag);
      return flag;
    },

    // 清空选择
    removeSelected() {
      this.selectedStartIndex = null;
      this.selectedEndIndex = null;
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // let startTime, endTime;
          // 校验用户选择的时间列表是否正确
          const { selectedStartIndex, selectedEndIndex } = this;
          if (
            typeof selectedStartIndex !== "number" ||
            typeof selectedEndIndex !== "number"
          ) {
            this.$message.error("请选择会议时间！");
            return;
          }

          const date = values.date.format("YYYY-MM-DD");
          let startTime = `${date} ${this.timeList[selectedStartIndex].time}`;

          let endTime;

          if (selectedEndIndex === this.timeList.length - 1) {
            endTime = `${date} ${this.timeList[selectedEndIndex].time}`;
          } else {
            endTime = `${date} ${this.timeList[selectedEndIndex + 1].time}`;
          }

          const that = this;

          this.$confirm({
            title: this.messageList.find(
              (item) => item.value === "meeting-appointment"
            ).name,
            onOk() {
              that.control(startTime, endTime, values);
            },
          });
        }
      });
    },

    control(startTime, endTime, values) {
      const { serviceList } = values;

      this.saveLoading = true;
      editMeeting({
        id: this.id,
        ...values,

        applicantName: this.user.userName,

        date: undefined,
        startTime,
        endTime,
        serviceList: Array.isArray(serviceList)
          ? serviceList.map((item) => ({
            code: item,
          }))
          : undefined,
        attendeeList: this.attendeeList.map((item) => ({
          userId: item.userId,
          userName: item.name,
        })),
      })
        .then(() => {
          this.$close(this.$route.path);
        })
        .finally(() => {
          this.saveLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.time {
  border: 1px solid #d0d0d0;
  height: 24px;
  width: 24px;
  line-height: 24px;
  cursor: pointer;
}

.value {
  height: 24px;
  line-height: 24px;
  font-size: 10px;
}

.disabled {
  background-color: #f0f0f0;
}

.active {
  background-color: #1890ff;
}

.center {
  margin-top: 80px;
}
</style>
